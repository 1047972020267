import { Add } from "@material-ui/icons";
import Delete from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import React, { useState } from "react";

import {
  Button,
  Card,
  Col,
  Form,
  Image,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import Swal from "sweetalert2";
import validator from "validator";

function confirmationAllert(name) {
  Swal.fire({
    title: `Are you sure delete ${name} ?`,
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
    }
  });
}

function showAllert2() {
  Swal.fire("Success", "Data profile berhasil disimpan", "success");
}

const ProfileForm = (props) => {
  const [boothImg, setBoothImg] = useState(undefined);
  const [logoImg, setLogoImg] = useState(undefined);

  const form = useFormik({
    initialValues: {
      boothName: "",
    },
    onSubmit: async (values) => {
      if (validator.isEmpty(values.boothName))
        return Swal.fire("Failed", "Nama booth tidak boleh kosong!", "warning");

      Swal.fire("Success", "Berhasil menambahkan booth baru.", "success");
    },
  });

  const [items, setItems] = useState([
    {
      name: "",
    },
  ]);

  const addItem = (event) => {
    event.preventDefault();
    setItems([
      ...items,
      {
        name: "",
      },
    ]);
  };
  const handleRemoveItem = (event) => {
    var tempItems = [...items];
    tempItems.splice(-1, 1);
    setItems(tempItems);
  };

  return (
    <>
      <Form onSubmit={form.handleSubmit}>
        <Row>
          <Col className="pr-1" sm="12" md="6">
            <Form.Group>
              <label>Booth Name</label>
              <Form.Control
                defaultValue={form.values.boothName}
                placeholder="Booth Name"
                type="text"
                name="boothName"
                onChange={form.handleChange}
              ></Form.Control>
            </Form.Group>

            {items.map((value, index) => (
              <Col className="pr-1">
                <Form.Group>
                  <label>Pic Name {index + 1}</label>
                  <Form.Control
                    placeholder="Facebook Account"
                    type="text"
                    name="facebookAccount"
                    onChange={form.handleChange}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label>Pic Number {index + 1}</label>
                  <Form.Control
                    placeholder="Facebook Account"
                    type="text"
                    name="facebookAccount"
                    onChange={form.handleChange}
                  ></Form.Control>
                </Form.Group>
              </Col>
            ))}
            <Col style={{ marginBottom: 16 }}>
              <Button
                className="btn-fill pull-right"
                onClick={addItem}
                variant="primary"
                active
              >
                <Add /> Add Contact
              </Button>
              <Button
                className="btn-fill pull-right"
                variant="danger"
                onClick={handleRemoveItem}
                style={{ marginLeft: 16 }}
                active
              >
                <Delete /> Remove Contact
              </Button>
            </Col>
            <Image
              className="img-fluid"
              src={boothImg != undefined ? URL.createObjectURL(boothImg) : null}
              style={{ marginTop: 16 }}
            />
            <Form.Group>
              <label>Booth Banner (16:9)</label>
              <Form.Control
                type="file"
                name="boothImage"
                onChange={(event) => setBoothImg(event.currentTarget.files[0])}
                accept=".jpg,.jpeg,.png"
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col className="pr-1" sm="12" md="6">
            <Image
              className="img-fluid img-thumbnail"
              src={logoImg != undefined ? URL.createObjectURL(logoImg) : null}
            />
            <Form.Group>
              <label>Loggo (1:1)</label>
              <Form.Control
                type="file"
                name="logoImage"
                onChange={(event) => setLogoImg(event.currentTarget.files[0])}
                accept=".jpg,.jpeg,.png"
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Button
          className="btn-fill pull-right"
          variant="primary"
          style={{ marginTop: 32 }}
          type="submit"
        >
          Save
        </Button>
        <div className="clearfix"></div>
      </Form>
    </>
  );
};

export default ProfileForm;
