import React, { Component, forwardRef, useState } from "react";
import { Card, Container, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import MUIDataTable from "mui-datatables";

import DatePicker from "react-date-picker";
import Dashboard from "./Dashboard";
import DetailBooth from "./DetailBooth";
import Maps from "./Maps";
import Visitor from "./Visitor";
import { Typography } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";

const EditorComponent = () => (
  <Editor
    wrapperClassName="wrapper-class"
    editorClassName="editor-class"
    toolbarClassName="toolbar-class"
  />
);

function showAllert() {
  Swal.fire("Success", "Informasi berhasil ditambahkan", "success");
}

class AboutUs extends Component {
  componentDidMount() {
    var editor = new Jodit("#editor");
  }

  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Container fluid>
          <textarea id="editor" name="editor"></textarea>
          <Button
            variant="primary"
            onClick={showAllert}
            active
            style={{ marginTop: 16 }}
          >
            Save
          </Button>
        </Container>
      </>
    );
  }
}

export default AboutUs;
