import React, { Component, forwardRef, useState } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Modal,
  NavLink,
} from "react-bootstrap";
import MUIDataTable from "mui-datatables";

import DatePicker from "react-date-picker";
import Dashboard from "./Dashboard";
import DetailBooth from "./DetailBooth";
import Maps from "./Maps";
import Visitor from "./Visitor";
import { Typography } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AboutUs from "./AboutUs";
import Banner from "./Banner";
import ReactOwlCarousel from "react-owl-carousel";
import Img1 from "../assets/img/logoStratup/1.png";
import Img2 from "../assets/img/logoStratup/2.png";
import Img3 from "../assets/img/logoStratup/3.png";
import Img4 from "../assets/img/logoStratup/4.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./BoothList.css";
import { width } from "@mui/system";
import Swal from "sweetalert2";
import Delete from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";

const options = {
  singleItem: true,
  loop: false,
  margin: 10,
  nav: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
  navText: [
    "<div class='nav-button owl-prev'>‹</div>",
    "<div class='nav-button owl-next'>›</div>",
  ],
};

class BoothList extends Component {
  confirmationAllert(name) {
    Swal.fire({
      title: `Are you sure delete ${name} ?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      booth: [
        {
          image: Img1,
          name: "Travina",
          visitor: 300,
          id: 1,
          visitorAvrg: 100,
        },
        {
          image: Img2,
          name: "Bibo Course",
          visitor: 300,
          id: 2,
          link: "Booth A",
          visitorAvrg: 100,
        },
        { image: Img3, name: "Booth B", visitor: 300, id: 3, visitorAvrg: 100 },
        {
          image: Img4,
          name: "Nusia Education",
          visitor: 300,
          id: 4,
          visitorAvrg: 100,
        },
      ],
      showChooseBooth: false,
    };
    this.updateDialog = this.updateDialog.bind(this);
  }

  updateDialog(show) {
    this.setState({ showChooseBooth: show });
  }

  render() {
    return (
      <>
        <Container fluid>
          <Row className="justify-content-end">
            <Link to="NewBooth" className="btn btn-success  active">
              New Booth
            </Link>
          </Row>

          <Row>
            <Modal
              show={this.state.showChooseBooth}
              onHide={() => this.updateDialog(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Select type Booth</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Card
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Card.Img variant="top" src={"sksk"} />
                  <Card.Body>
                    <Card.Title style={{ marginBottom: 16 }}></Card.Title>
                  </Card.Body>
                  <NavLink to="components/detail-booth/1/galang/12/66">
                    <img
                      src=""
                      className="img-fluid"
                      alt="Logo Bank"
                      style={{ paddingBottom: "15px" }}
                    />
                  </NavLink>{" "}
                </Card>
                Primary Booth
                <Button variant="secondary">
                  <Card
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Card.Img variant="top" src={"sksk"} />
                    <Card.Body>
                      <Card.Title style={{ marginBottom: 16 }}></Card.Title>
                    </Card.Body>
                  </Card>
                  Secondary Booth
                </Button>
              </Modal.Body>
            </Modal>
          </Row>

          <Row>
            <ReactOwlCarousel
              className="owl-theme "
              loop
              nav
              margin={8}
              {...options}
              style={{ padding: 20 }}
            >
              {this.state.booth.map((value, index) => (
                <Row className="justify-content-md-center" key={index}>
                  <Card
                    style={{
                      width: "50rem",
                      textAlign: "center",
                    }}
                  >
                    <Card.Body>
                      <Card.Title style={{ marginBottom: 16 }}>
                        {value.name}
                      </Card.Title>
                      <Link
                        to={`EditBooth/1/${value.name}`}
                        className="btn btn-primary active"
                        style={{ width: 100 }}
                      >
                        Edit
                      </Link>

                      <Button
                        variant="primary"
                        active
                        onClick={() => this.confirmationAllert(value.name)}
                        variant="danger"
                        style={{ marginLeft: 16, width: 100 }}
                      >
                        Delete
                      </Button>
                    </Card.Body>
                    <Card.Img variant="top" src={value.image} />
                  </Card>
                </Row>
              ))}
            </ReactOwlCarousel>
          </Row>
        </Container>
      </>
    );
  }
}

export default BoothList;
