import React from "react";
import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useHistory, NavLink } from "react-router-dom";
import { CardBody, CardHeader } from "reactstrap";
import Img1 from "../assets/img/logoStratup/1.png";
import Img2 from "../assets/img/logoStratup/2.png";
import Img3 from "../assets/img/logoStratup/3.png";
import Img4 from "../assets/img/logoStratup/4.png";
import Img5 from "../assets/img/logoStratup/5.png";
import Img6 from "../assets/img/logoStratup/6.png";
import Img7 from "../assets/img/logoStratup/7.png";
import Img8 from "../assets/img/logoStratup/8.png";
import Img9 from "../assets/img/logoStratup/9.png";
import Img10 from "../assets/img/logoStratup/10.png";
import Img11 from "../assets/img/logoStratup/11.png";
import Img12 from "../assets/img/logoStratup/12.png";
import Img13 from "../assets/img/logoStratup/13.png";
import Img14 from "../assets/img/logoStratup/14.png";
import Img15 from "../assets/img/logoStratup/15.png";
import Img16 from "../assets/img/logoStratup/16.png";
import Img17 from "../assets/img/logoStratup/17.png";
import Img18 from "../assets/img/logoStratup/18.png";

class Booth extends React.Component {
  constructor(props) {
    super(props);
    this.getDataPortofilios = this.getDataBooth.bind(this);
    this.state = {
      isLoading: null,
        booth: [
          {
            image: Img1,
            name:"Travina",
            visitor: 300,
            id:1,
            visitorAvrg: 100
          },
          {
            image: Img2,
            name:"Bibo Course",
            visitor: 300,
            id:2,
            link:"Booth A",
            visitorAvrg: 100
          },
          {image: Img3,
            name:"Booth B",
            visitor: 300,
            id:3,
            visitorAvrg: 100
          },
          {
            image: Img4,
            name:"Nusia Education",
            visitor: 300,
            id:4,
            visitorAvrg: 100
          },
          {
            image: Img5,
            name:"Louca",
            visitor: 300,
            id:5,
            visitorAvrg: 100
          },
          {
            image: Img6,
            name:"Kulakan",
            visitor: 300,
            id:6,
            visitorAvrg: 100
          },
          {
            image: Img7,
            name:"Booth C",
            visitor: 300,
            id:7,
            visitorAvrg: 100
          },
          {
            image: Img8,
            name:"Cley",
            visitor: 300,
            id:8,
            visitorAvrg: 100
          },{
            image: Img9,
            name:"Nalarin",
            visitor: 300,
            id:9,
            visitorAvrg: 100
          },
          {
            image: Img10,
            name:"Devloopia",
            visitor: 300,
            id:9,
            visitorAvrg: 100
          },
          {
            image: Img11,
            name:"Bakul",
            visitor: 300,
            id:10,
            visitorAvrg: 100
          },
          {
            image: Img12,
            name:"Play",
            visitor: 300,
            id:11,
            visitorAvrg: 100
          },
          {
            image: Img13,
            name:"Lapak Ninja",
            visitor: 300,
            id:13,
            visitorAvrg: 100
          },
          {
            image: Img14,
            name:"Nakulasadewa",
            visitor: 300,
            id:14,
            visitorAvrg: 100
          },
          {
            image: Img15,
            name:"Haiwanita.com",
            visitor: 300,
            id:15,
            visitorAvrg: 100
          },
          {
            image: Img16,
            name:"Golek",
            visitor: 300,
            id:16,
            visitorAvrg: 100
          },
          {
            image: Img17,
            name:"MyEco",
            visitor: 300,
            id:17,
            visitorAvrg: 100
          },
          {
            image: Img18,
            name:"Paktukang",
            visitor: 300,
            id:18,
            visitorAvrg: 100
          }
        ],
        allVisitor:[
            {
                total:"1.200"
            }

        ],
        averageVisitor:[
            {
                average:300
            }
        ],
        potentialLead:[
            {
                potential:400
            }
        ],
        potentialAverage:[
            {
                potentialAverage:200
            }
        ]

    };
  }

  async getDataBooth() {
  }
  render() {
    return (
        <>
        <Container fluid>
        <Row style={{justifyContent:"center"}}>
            {this.state.allVisitor.map((item, index) => (
            <Col lg="6" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart-pie-35 text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Visitor All Booth </p>
                      <Card.Title as="h4">{item.total}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {this.state.averageVisitor.map((item, index) => (
        <Col lg="6" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Average Visitor All Booth </p>
                      <Card.Title as="h4">{item.average}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
    ))}
        {this.state.potentialLead.map((item, index) => (
        <Col lg="6" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart-pie-36 text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Potential Lead Generate Perday </p>
                      <Card.Title as="h4">{item.potential}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}

{this.state.potentialAverage.map((item, index) => (
        <Col lg="6" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart-pie-36 text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Average Potential Lead Generate Perday </p>
                      <Card.Title as="h4">{item.potentialAverage}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
        </Row>
        <Row>
          {this.state.booth.map((item, index) => (
          <Col lg="3" id="booth">
            <Card className="card-stats">
              <div className="booth">
                <CardBody style={{textAlign:"center"}}>
                  <NavLink to={`components/detail-booth/${item.id}/${item.name}/${item.visitor}/${item.visitorAvrg}`}>
                    <img src={item.image} className="img-fluid" alt="Logo Bank"  style={{ paddingBottom:"15px"}}/>
                  </NavLink>
                </CardBody>
                <Card.Footer style={{textAlign:"center"}}>
                  <h4 className="m-0">{item.name}</h4>
                  <p> Visitor : {item.visitor}</p>
                </Card.Footer>
              </div>
            </Card>
          </Col>))}
         </Row>
        </Container>
        </>
    );
  }
}

export default Booth;
