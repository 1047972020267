import React, { Component, useState } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Form,
  Image,
} from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";

import Delete from "@mui/icons-material/Delete";

import Img1 from "../../assets/img/logoStratup/1.png";
import Img2 from "../../assets/img/logoStratup/2.png";
import Img3 from "../../assets/img/logoStratup/3.png";
import Img4 from "../../assets/img/logoStratup/4.png";
import { CardBody } from "reactstrap";
import { useFormik } from "formik";
import SubCatalog from "components/Booth/SubCatalog";
import Catalog from "components/Booth/Catalog";
import { Item } from "devextreme-react/funnel";

function successAllert() {
  Swal.fire("Success", "Banner berhasil di perbarui", "success");
}

function confirmationAllert() {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
    }
  });
}

const Attachment = (props) => {
  const [items, setItems] = useState([
    {
      name: "Maximus Booth Test",
      subCatalogs: [
        {
          name: "Maximus Sub Booth Test 1",
          attachment: undefined,
          logoImg: undefined,
        },
        {
          name: "Maximus Sub Booth Test 2",
          attachment: undefined,
          logoImg: undefined,
        },
      ],
      logoImg: undefined,
    },
  ]);
  const addItem = (event) => {
    event.preventDefault();
    setItems([
      ...items,
      {
        name: "",
        subCatalogs: [],
        logoImg: undefined,
      },
    ]);
  };

  const handleRemoveItem = (event) => {
    var tempItems = [...items];
    tempItems.splice(-1, 1);
    setItems(tempItems);
  };
  const form = useFormik({
    initialValues: {
      boothName: "",
    },
    onSubmit: async (values) => {
      if (validator.isEmpty(values.boothName))
        return Swal.fire("Failed", "Nama booth tidak boleh kosong!", "warning");

      Swal.fire("Success", "Berhasil menambahkan booth baru.", "success");
    },
  });

  return (
    <>
      <Container fluid>
        <Form onSubmit={form.handleSubmit}>
          <Row>
            {items.map((item, index) => (
              <Catalog
                item={item}
                form={form}
                number={index + 1}
                removeCatalog={() => {
                  items.splice(index, 1);
                }}
                updatUI={() => {
                  setItems([...items]);
                }}
              ></Catalog>
            ))}
          </Row>

          <Row>
            <Button
              variant="primary"
              onClick={addItem}
              active
              style={{ width: 200 }}
            >
              Add New Catalog
            </Button>
          </Row>
          <Row>
            <Button
              style={{ width: 200, marginTop: 16 }}
              variant="success"
              onClick={successAllert}
              type="submit"
              active
            >
              Save
            </Button>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Attachment;
