import { useFormik } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import validator from "validator";

const ChangePasswordForm = (props) => {
  const form = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      const password = "Admin123";

      if (validator.isEmpty(values.currentPassword))
        return Swal.fire(
          "Failed",
          "Current password tidak boleh kosong!",
          "warning"
        );
      if (validator.isEmpty(values.newPassword))
        return Swal.fire(
          "Failed",
          "New Passsword tidak boleh kosong!",
          "warning"
        );
      if (validator.isEmpty(values.confirmPassword))
        return Swal.fire(
          "Failed",
          "Confirm password tidak boleh kosong!",
          "warning"
        );
      if (values.currentPassword != password)
        return Swal.fire("Failed", "Current password tidak sama!", "warning");
      if (values.newPassword != values.confirmPassword)
        return Swal.fire(
          "Failed",
          "Confirm dan new password tidak sama!",
          "warning"
        );

      if (
        !values.newPassword.match(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
        )
      )
        return Swal.fire(
          "Failed",
          "Kata sandi wajib mengandung angka, huruf kecil, dan huruf besar",
          "warning"
        );

      Swal.fire("Success", "Berhasil update password", "success");
    },
  });
  return (
    <>
      <Form onSubmit={form.handleSubmit}>
        <Row>
          <Col className="pr-1" sm="12" md="6">
            <Form.Group>
              <label>Current Password</label>
              <Form.Control
                defaultValue=""
                placeholder="Current Password"
                type="password"
                name="currentPassword"
                onChange={form.handleChange}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="pr-1" sm="12" md="6">
            <Form.Group>
              <label>New Password</label>
              <Form.Control
                defaultValue=""
                placeholder="New Password"
                type="password"
                name="newPassword"
                onChange={form.handleChange}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="pr-1" sm="12" md="6">
            <Form.Group>
              <label>Confirm New Password</label>
              <Form.Control
                defaultValue=""
                placeholder="Confirm New Password"
                type="password"
                name="confirmPassword"
                onChange={form.handleChange}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Button className="btn-fill pull-right" type="submit" variant="primary">
          Update
        </Button>
        <div className="clearfix"></div>
      </Form>
    </>
  );
};

export default ChangePasswordForm;
