import React, { forwardRef, useState } from "react";
import { Card, Container, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Banner from "../Banner";
import Attachment from "./Attachment";
import Description from "./Description";
import ProfileForm from "./ProfieForm";

function NewBooth() {
  return (
    <>
      <Container fluid>
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="profile" title="Profile">
            <ProfileForm></ProfileForm>
          </Tab>
          <Tab eventKey="description" title="Description">
            <Description></Description>
          </Tab>
          <Tab eventKey="attachment" title="Attachment">
            <Attachment></Attachment>
          </Tab>
        </Tabs>
      </Container>
    </>
  );
}

export default NewBooth;
