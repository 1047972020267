import React from "react";
import ChartistGraph from "react-chartist";
import {Card,Container,Row,Col,} from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import Funnel, {Title,Margin,Export,Tooltip,Item, Border,Label} from 'devextreme-react/funnel';
import { Divider } from "@material-ui/core";


const columns = [
  {
   name: "name",  
   label: "Name",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
   name: "date",
   label: "Date",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
   name: "time",
   label: "Time",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
   name: "activity",
   label: "Activity",
    options: {
      filter: true,
      sort: false,
    }
  },
 ];
 
 const data = [
  { name: "Joe James", date: "04 Juni 2021", time: "09:04:07", activity: "Click Booth Travina" },
  { name: "John Walsh", date: "04 Juni 2021", time: "09:04:10", activity: "Click Booth Kulakan" },
  { name: "Bob Herm", date: "04 Juni 2021", time: "09:04:11", activity: "Click Booth Nalarin" },
  { name: "James Houston", date: "04 Juni 2021", time: "19:04:07", activity: "Click Booth Developia" },
  { name: "Joe James", date: "04 Juni 2021", time: "09:04:09", activity: "Click Booth Travina" },
  { name: "John Walsh", date: "04 Juni 2021", time: "19:04:07", activity: "Click Booth Kulakan" },
  { name: "Bob Herm", date: "04 Juni 2021", time: "08:04:07", activity: "Click Booth Nalarin" },
  { name: "James Houston", date: "04 Juni 2021", time: "19:04:07", activity: "Click Booth Developia" },
  { name: "Joe James", date: "10 Juni 2021", time: "09:04:07", activity: "Click Boother Travina" },
  { name: "John Walsh", date: "10 Juni 2021", time: "20:04:07", activity: "Click Boother Kulakan" },
  { name: "Bob Herm", date: "10 Juni 2021", time: "10.10", activity: "Click Baooth Nalarin" },
  { name: "James Houston", date: "10 Juni 2021", time: "19:04:07", activity: "Click Booth Developiaage" },
  { name: "Joe James", date: "10 Juni 2021", time: "09:04:07", activity: "Click Booth Travina" },
  { name: "John Walsh", date: "10 Juni 2021", time: "21:04:07", activity: "Click Booth Kulakan" },
  { name: "Bob Herm", date: "10 Juni 2021", time: "09:04:07", activity: "Click Booth Nalarin" },
  { name: "James Houston", date: "10 Juni 2021", time: "19:04:07", activity: "Click Booth Developia" },
 ];

 const dataSource = [
  { argument: 'Awareness', value: 50, color: "#60a69f" },
  { argument: 'Engage', value: 30, color:"#78b6d9" },
  { argument: 'Lead', value: 20, color:"#6682bb" }
];
 
 const options = {
   filterType: 'checkbox',
   print: false,
   download: false,
   
 };


function Visitor() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Visitors</p>
                      <Card.Title as="h4">3.000</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-tap-01 text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Average Visitor PerDay</p>
                      <Card.Title as="h4">1.000</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart-pie-36 text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Business Card Exchange PerDay</p>
                      <Card.Title as="h4">800</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-email-85 text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Chat Networking PerDay</p>
                      <Card.Title as="h4">550</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Visitor PerDay</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="ct-chart" id="chartHours">
                  <ChartistGraph
                    data={{
                      labels: [
                        "09:00",
                        "12:00",
                        "13:00",
                        "15:00",
                        "18:00",

                      ],
                      series: [
                        [400, 785,  690, 592,190],
                        [452, 760, 503, 540,290],
                        [413, 700, 567, 508,190],
                      ],
                    }}
                    type="Line"
                    options={{
                      low: 0,
                      high: 800,
                      showArea: false,
                      height: "245px",
                      axisX: {
                        showGrid: false,
                      },
                      lineSmooth: true,
                      showLine: true,
                      showPoint: true,
                      fullWidth: true,
                      chartPadding: {
                        right: 50,
                      },
                    }}
                    responsiveOptions={[
                      [
                        "screen and (max-width: 640px)",
                        {
                          axisX: {
                            labelInterpolationFnc: function (value) {
                              return value[0];
                            },
                          },
                        },
                      ],
                    ]}
                  />
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  Open <i className="fas fa-circle text-danger"></i>
                  Click <i className="fas fa-circle text-warning"></i>
                  Click Second Time
                </div>
                <hr></hr>
                <div className="stats">
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Filter by Profession</Card.Title>
              </Card.Header>
              <Card.Body>
                <div
                  className="ct-chart ct-perfect-fourth"
                  id="chartPreferences"
                >
                  <ChartistGraph
                    data={{
                      labels: ["40%", "20%", "30%" ,"10"],
                      series: [40, 20, 40,10],
                    }}
                    type="Pie"
                  />
                </div>
                <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  IT  <i className="fas fa-circle text-danger"></i>
                  Marketing <i className="fas fa-circle text-warning"></i>
                  Finance <i className="fas fa-circle text-warning"></i>
                  Other
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>        
        <Row>
        <Col md="4">
            <Card>
              <Card.Body>
              <Funnel id="funnel"
                  dataSource={dataSource}
                  palette="Soft Pastel"
                  argumentField="argument"
                  valueField="value"
                >
                  <Title text="Marketing Funnel for Visitor">
                    <Margin bottom={30}/>
                  </Title>
                  <Export enabled={true} />
                  <Tooltip enabled={true} format="fixedPoint" />
                  <Item>
                    <Border visible={true} />
                  </Item>
                  <Label
                    visible={true}
                    position="inside"
                    backgroundColor="none"
                  />
                </Funnel>
                </Card.Body>
                <Card.Footer>
                <div className="legend"> <i className="fas fa-circle text-info"  style={{color:"#60a69f"}}></i> Aawareness : Total participants attended the event
                 <br></br> <i className="fas fa-circle" style={{color:" #78b6d9"}}></i> Engage :  Total participants clicked the booth &gt; 3x
                 <br></br> <i className="fas fa-circle" style={{color:"#6682bb"}}></i> Lead : Total participants clicked chat
                </div>
                <hr></hr>
                <div className="stats">
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="8">
            <Card>
              <MUIDataTable
                title={"Tabel Visitor"}
                data={data}
                columns={columns}
                options={options}
              />
              </Card>
          </Col>
        </Row>          
      </Container>
    </>
  );
}


export default Visitor;
