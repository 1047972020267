import React, { Component, useState } from "react";
import { Row, Col, Form, Image, Button, Card } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SubCatalog from "./SubCatalog";

const Catalog = (props) => {
  return (
    <>
      <Col className="pr-1" sm="12" md="12">
        <Card>
          <Card.Body>
            <Row>
              <Col sm="12" md="4">
                <Form.Group>
                  <label>Catalog Name {props.number}</label>
                  <Form.Control
                    placeholder="Booth Name"
                    type="text"
                    defaultValue={props.item.name}
                    name="boothName"
                  ></Form.Control>
                </Form.Group>
                <Image
                  className="w-50"
                  src={
                    props.item.logoImg != undefined
                      ? URL.createObjectURL(props.item.logoImg)
                      : null
                  }
                />
                <Form.Group>
                  <label>Catalog Picture {props.number}</label>
                  <Form.Control
                    type="file"
                    name="logoImage"
                    onChange={(event) => {
                      props.item.logoImg = event.currentTarget.files[0];
                      props.updatUI();
                    }}
                    accept=".jpg,.jpeg,.png"
                  ></Form.Control>
                </Form.Group>
                <Button
                  variant="danger"
                  onClick={() => {
                    props.removeCatalog();
                    props.updatUI();
                  }}
                  active
                  style={{ width: 200 }}
                >
                  Remove Catalog
                </Button>
              </Col>
              <Col sm="12" md="8">
                <Button
                  variant="info"
                  onClick={() => {
                    props.item.subCatalogs = [
                      ...props.item.subCatalogs,
                      {
                        name: "",
                        attachment: undefined,
                        logoImg: undefined,
                      },
                    ];
                    props.updatUI();
                  }}
                  active
                  style={{ width: 200, marginTop: 16 }}
                >
                  Add Sub Product
                </Button>
                {props.item.subCatalogs.map((subCatalog, indexSub) => (
                  <SubCatalog
                    item={subCatalog}
                    number={indexSub + 1}
                    logoChange={(result) => {
                      subCatalog.logoImg = result;
                      props.updatUI();
                    }}
                    removeSubCatalog={() => {
                      props.item.subCatalogs.splice(indexSub, 1);
                      props.updatUI();
                    }}
                  ></SubCatalog>
                ))}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default Catalog;
