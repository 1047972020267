import React from "react";
import ChartistGraph from "react-chartist";
import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Image1 from "../assets/img/bca.jpg";
import MUIDataTable from "mui-datatables";

const columns = [
  {
   name: "nama",
   label: "Nama",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "tanggal",
   label: "Tanggal",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "waktu",
   label: "Waktu",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "aktivitas",
   label: "Aktivitas",
   options: {
    filter: true,
    sort: false,
   }
  },
 ];
 
 const data = [
  { nama: "Joe James", tanggal: "04 Juni 2021", waktu: "09:04:07", aktivitas: "Click Banner" },
  { nama: "John Walsh", tanggal: "04 Juni 2021", waktu: "09:04:10", aktivitas: "Click About Us" },
  { nama: "Bob Herm", tanggal: "04 Juni 2021", waktu: "09:04:11", aktivitas: "Click More Info" },
  { nama: "James Houston", tanggal: "04 Juni 2021", waktu: "19:04:07", aktivitas: "Click Image" },
  { nama: "Joe James", tanggal: "04 Juni 2021", waktu: "09:04:09", aktivitas: "Click Banner" },
  { nama: "John Walsh", tanggal: "04 Juni 2021", waktu: "19:04:07", aktivitas: "Click About Us" },
  { nama: "Bob Herm", tanggal: "04 Juni 2021", waktu: "08:04:07", aktivitas: "Click More Info" },
  { nama: "James Houston", tanggal: "04 Juni 2021", waktu: "19:04:07", aktivitas: "Click Image" },
  { nama: "Joe James", tanggal: "10 Juni 2021", waktu: "09:04:07", aktivitas: "Click Banner" },
  { nama: "John Walsh", tanggal: "10 Juni 2021", waktu: "20:04:07", aktivitas: "Click About Us" },
  { nama: "Bob Herm", tanggal: "10 Juni 2021", waktu: "10.10", aktivitas: "Click More Info" },
  { nama: "James Houston", tanggal: "10 Juni 2021", waktu: "19:04:07", aktivitas: "Click Image" },
  { nama: "Joe James", tanggal: "10 Juni 2021", waktu: "09:04:07", aktivitas: "Click Banner" },
  { nama: "John Walsh", tanggal: "10 Juni 2021", waktu: "21:04:07", aktivitas: "Click About Us" },
  { nama: "Bob Herm", tanggal: "10 Juni 2021", waktu: "09:04:07", aktivitas: "Click More Info" },
  { nama: "James Houston", tanggal: "10 Juni 2021", waktu: "19:04:07", aktivitas: "Click Image" },
 ];
 
 const options = {
   filterType: 'checkbox',
 };


class DetailBooth extends React.Component {
  constructor(props) {
    super(props);
    this.getDataPortofilios = this.getDataBooth.bind(this);
    this.state = {
      isLoading: null,
        booth: [
          {
            image: Image1, 
            name:"Bank BCA",
            TotalVisitor: 300,
            AvarageVisitor:100
          }
        ],
        allVisitor:[
            {
                total:1200
            }
        
        ],
        averageVisitor:[
            {
                average:300
            }
        ],
        potentialLead:[
            {
                potential:400
            }
        ]

    };
  }

  async getDataBooth() {
  }
  render() {
    return (
        <> 
        <Container fluid>
            {this.state.booth.map((item, index) => (
            <Row >
            <Col lg="4" sm="6">
                    <Card className="card-stats">
                        <Card.Body>
                            <Row>
                            <Col xs="5">
                                <div className="icon-big text-center icon-warning">
                                <i className="nc-icon nc-chart text-warning"></i>
                                </div>
                            </Col>
                            <Col xs="7">
                                <div className="numbers">
                                <p className="card-category">Total Visitor Booth</p>
                                <p className="card-category">{item.name}</p>
                                <Card.Title as="h4">{item.TotalVisitor} </Card.Title>
                                </div>
                            </Col>
                            </Row>
                        </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                    <Card className="card-stats">
                        <Card.Body>
                            <Row>
                            <Col xs="5">
                                <div className="icon-big text-center icon-warning">
                                <i className="nc-icon nc-chart-pie-36 text-warning"></i>
                                </div>
                            </Col>
                            <Col xs="7">
                                <div className="numbers">
                                <p className="card-category">Average Visitor Per Day</p>
                                <p className="card-category">{item.name}</p>
                                <Card.Title as="h4">{item.AvarageVisitor}</Card.Title>
                                </div>
                            </Col>
                            </Row>
                        </Card.Body>
                </Card>
              </Col>
            </Row>
        ))}
        <Row style={{paddingTop:"20px"}}>
        <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Visitor Booth Per Day</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="ct-chart" id="chartHours">
                  <ChartistGraph
                    data={{
                      labels: [
                        "9:00AM",
                        "12:00AM",
                        "3:00PM",
                        "6:00PM",
                        "9:00PM",
                        "12:00PM",
                        "3:00AM",
                        "6:00AM",
                      ],
                      series: [
                        [287, 385, 490, 492, 554, 586, 698, 695],
                        [67, 152, 143, 240, 287, 335, 435, 437],
                        [23, 113, 67, 108, 190, 239, 307, 308],
                      ],
                    }}
                    type="Line"
                    options={{
                      low: 0,
                      high: 800,
                      showArea: false,
                      height: "245px",
                      axisX: {
                        showGrid: false,
                      },
                      lineSmooth: true,
                      showLine: true,
                      showPoint: true,
                      fullWidth: true,
                      chartPadding: {
                        right: 50,
                      },
                    }}
                    responsiveOptions={[
                      [
                        "screen and (max-width: 640px)",
                        {
                          axisX: {
                            labelInterpolationFnc: function (value) {
                              return value[0];
                            },
                          },
                        },
                      ],
                    ]}
                  />
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  Open <i className="fas fa-circle text-danger"></i>
                  Click <i className="fas fa-circle text-warning"></i>
                  Click Second Time
                </div>
                <hr></hr>
                <div className="stats">
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Parameter Grafik</Card.Title>
              </Card.Header>
              <Card.Body>
                <div
                  className="ct-chart ct-perfect-fourth"
                  id="chartPreferences"
                >
                  <ChartistGraph
                    data={{
                      labels: ["40%", "20%", "30%" ,"10"],
                      series: [40, 20, 40,10],
                    }}
                    type="Pie"
                  />
                </div>
                <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  IT  <i className="fas fa-circle text-danger"></i>
                  Marketing <i className="fas fa-circle text-warning"></i>
                  Finance <i className="fas fa-circle text-warning"></i>
                  Other
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Marketing Funnel for Visitor</Card.Title>
              </Card.Header>
              <Card.Body>
                <div
                  className="ct-chart ct-perfect-fourth"
                  id="chartPreferences"
                >
                  <ChartistGraph
                    data={{
                      labels: [ "60%", "40%"],
                      series: [60, 40],
                    }}
                    type="Pie"
                  />
                </div>
                <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  Engage  <i className="fas fa-circle text-warning"></i>
                  Lead 
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="8">
          <MUIDataTable
                title={"Tabel Visitor"}
                data={data}
                columns={columns}
                options={options}
              />
          </Col>
        </Row>
        </Container>
        </>
    );
  }
}

export default DetailBooth;
