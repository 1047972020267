/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Booth from "views/Booth";
import Visitor from "views/Visitor";
import BoothDetail from "components/detailBooth/booth-detail.js";
import ExpoVisitor from "views/ExpoVisitor";
import Content from "views/Content";
import BoothList from "views/BoothList";
import Settings from "views/Settings";
import SocialMedia from "views/SocialMedia";
import EditBooth from "views/EditBooth/EditBooth";
import NewBooth from "views/New Booth/NewBooth";

const dashboardRoutes = [
  {
    path: "/ExpoVisitor",
    name: "Expo Visitor ",
    icon: "nc-icon nc-single-02",
    component: ExpoVisitor,
    layout: "/admin",
    show: true,
  },
  {
    path: "/Content",
    name: "Content",
    icon: "nc-icon nc-paper-2",
    component: Content,
    layout: "/admin",
    show: true,
  },
  {
    path: "/ListBooth",
    name: "Booth",
    icon: "nc-icon nc-bold",
    component: BoothList,
    layout: "/admin",
    show: true,
  },
  {
    path: "/EditBooth/:id/:name",
    name: "Edit Booth",
    icon: "nc-icon nc-bank",
    component: EditBooth,
    layout: "/admin",
    show: false,
  },
  {
    path: "/NewBooth",
    name: "New Booth",
    icon: "nc-icon nc-bank",
    component: NewBooth,
    layout: "/admin",
    show: false,
  },
  {
    path: "/Visitor",
    name: "Visitor Analytics ",
    icon: "nc-icon nc-chart-pie-36",
    component: Visitor,
    layout: "/admin",
    show: true,
  },
  {
    path: "/Booth",
    name: "Booth Analytics",
    icon: "nc-icon nc-bank",
    component: Booth,
    layout: "/admin",
    show: true,
  },

  {
    path: "/components/detail-booth/:id/:name/:visitor/:visitorAvrg",
    name: "Booth Detail",
    icon: "nc-icon nc-bank",
    component: BoothDetail,
    layout: "/admin",
    show: false,
  },
  {
    path: "/Settings",
    name: "Settings",
    icon: "nc-icon nc-settings-gear-64",
    component: Settings,
    layout: "/admin",
    show: true,
  },
  {
    path: "/SocialMedia",
    name: "Social Media",
    icon: "nc-icon nc-air-baloon",
    component: SocialMedia,
    layout: "/admin",
    show: true,
  },
  // {
  //   path: "https://analytics.google.com/analytics/web/",
  //   name: "Google Analytics",
  //   icon: "nc-icon nc-bank",
  //   layout: "",
  //   show: true,
  // }
];

export default dashboardRoutes;
