import React from "react";
import ChartistGraph from "react-chartist";
import { Card, Container, Row, Col, CardBody } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import Funnel, {
  Title,
  Margin,
  Export,
  Tooltip,
  Item,
  Border,
  Label,
} from "devextreme-react/funnel";

const columns = [
  {
    name: "name",
    label: "Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "date",
    label: "Date",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "time",
    label: "Time",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "activity",
    label: "Activity",
    options: {
      filter: true,
      sort: false,
    },
  },
];

const data = [
  {
    name: "Joe James",
    date: "04 June 2021",
    time: "09:04:07",
    activity: "Click Banner",
  },
  {
    name: "John Walsh",
    date: "04 June 2021",
    time: "09:04:10",
    activity: "Click About Us",
  },
  {
    name: "Bob Herm",
    date: "04 June 2021",
    time: "09:04:11",
    activity: "Click More Info",
  },
  {
    name: "James Houston",
    date: "04 June 2021",
    time: "19:04:07",
    activity: "Click Image",
  },
  {
    name: "Joe James",
    date: "04 June 2021",
    time: "09:04:09",
    activity: "Click Banner",
  },
  {
    name: "John Walsh",
    date: "04 June 2021",
    time: "19:04:07",
    activity: "Click About Us",
  },
  {
    name: "Bob Herm",
    date: "04 June 2021",
    time: "08:04:07",
    activity: "Click More Info",
  },
  {
    name: "James Houston",
    date: "04 June 2021",
    time: "19:04:07",
    activity: "Click Image",
  },
  {
    name: "Joe James",
    date: "10 June 2021",
    time: "09:04:07",
    activity: "Click Banner",
  },
  {
    name: "John Walsh",
    date: "10 June 2021",
    time: "20:04:07",
    activity: "Click About Us",
  },
  {
    name: "Bob Herm",
    date: "10 June 2021",
    time: "10.10",
    activity: "Click More Info",
  },
  {
    name: "James Houston",
    date: "10 June 2021",
    time: "19:04:07",
    activity: "Click Image",
  },
  {
    name: "Joe James",
    date: "10 June 2021",
    time: "09:04:07",
    activity: "Click Banner",
  },
  {
    name: "John Walsh",
    date: "10 June 2021",
    time: "21:04:07",
    activity: "Click About Us",
  },
  {
    name: "Bob Herm",
    date: "10 June 2021",
    time: "09:04:07",
    activity: "Click More Info",
  },
  {
    name: "James Houston",
    date: "10 June 2021",
    time: "19:04:07",
    activity: "Click Image",
  },
];

const options = {
  filterType: "checkbox",
  print: false,
  download: false,
   
};

const dataSource = [
  { argument: "Engage", value: 50, color: "#60a69f" },
  { argument: "Lead", value: 40, color: "#78b6d9" },
];

class BoothDetail extends React.Component {
  constructor(props) {
    super(props);
    this.getDataPortofilios = this.getDataBooth.bind(this);
    this.state = {
      isLoading: null,
      booth: [{}],
      allVisitor: [
        {
          total: 1200,
        },
      ],
      averageVisitor: [
        {
          average: 300,
        },
      ],
      potentialLead: [
        {
          potential: 400,
        },
      ],
    };
  }

  async getDataBooth() {}
  render() {
    const { id, name, visitor, visitorAvrg } = this.props.match.params;
    return (
      <>
        <Container fluid>
          {this.state.booth.map((item, index) => (
            <Row>
              <Col lg="4" sm="6">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-chart text-warning"></i>
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Total Visitor All</p>
                          <p className="card-category">{name}</p>
                          <Card.Title as="h4">{visitor} </Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-chart-pie-36 text-warning"></i>
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">
                            Average Visitor Per Day
                          </p>
                          <p className="card-category">{name}</p>
                          <Card.Title as="h4">{visitorAvrg}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ))}
          <Row style={{ paddingTop: "20px" }}>
            <Col md="8">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Visitor Booth Per Day</Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="ct-chart" id="chartHours">
                    <ChartistGraph
                       data={{
                        labels: [
                          "09:00",
                          "12:00",
                          "13:00",
                          "15:00",
                          "18:00",
  
                        ],
                        series: [
                          [350, 785,  690, 592,190],
                          [402, 760, 503, 540,290],
                          [413, 650, 567, 508,190],
                        ],
                      }}
                      type="Line"
                      options={{
                        low: 0,
                        high: 800,
                        showArea: false,
                        height: "245px",
                        axisX: {
                          showGrid: false,
                        },
                        lineSmooth: true,
                        showLine: true,
                        showPoint: true,
                        fullWidth: true,
                        chartPadding: {
                        right: 50,
                        },
                      }}
                      responsiveOptions={[
                        [
                          "screen and (max-width: 640px)",
                          {
                            axisX: {
                              labelInterpolationFnc: function (value) {
                                return value[0];
                              },
                            },
                          },
                        ],
                      ]}
                    />
                  </div>
                </Card.Body>
                <Card.Footer>
                  <div className="legend">
                    <i className="fas fa-circle text-info"></i>
                    Open <i className="fas fa-circle text-danger"></i>
                    Click <i className="fas fa-circle text-warning"></i>
                    Click Second Time
                  </div>
                  <hr></hr>
                  <div className="stats"></div>
                </Card.Footer>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Parameter Grafik</Card.Title>
                </Card.Header>
                <Card.Body>
                  <div
                    className="ct-chart ct-perfect-fourth"
                    id="chartPreferences"
                  >
                    <ChartistGraph
                      data={{
                        labels: ["40%", "20%", "30%", "10"],
                        series: [40, 20, 40, 10],
                      }}
                      type="Pie"
                    />
                  </div>
                  <div className="legend">
                    <i className="fas fa-circle text-info"></i>
                    IT <i className="fas fa-circle text-danger"></i>
                    Marketing <i className="fas fa-circle text-warning"></i>
                    Finance <i className="fas fa-circle text-warning"></i>
                    Other
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <Card.Body>
                  <Funnel
                    id="funnel"
                    dataSource={dataSource}
                    palette="Soft Pastel"
                    argumentField="argument"
                    valueField="value"
                  >
                    <Title text="Marketing Funnel for Visitor">
                      <Margin bottom={30} />
                    </Title>
                    <Export enabled={true} />
                    <Tooltip enabled={true} format="fixedPoint" />
                    <Item>
                      <Border visible={true} />
                    </Item>
                    <Label
                      visible={true}
                      position="inside"
                      backgroundColor="none"
                    />
                  </Funnel>
                </Card.Body>
                <Card.Footer>
                  <div className="legend">
                    <i
                      className="fas fa-circle"
                      style={{ color: " #60a69f" }}
                    ></i>
                    Engage : Total participants clicked the booth &gt; 3x
                    <br></br>
                    <i
                      className="fas fa-circle text-info"
                      style={{ color: "#60a69f" }}
                    ></i>
                    Lead : Total participants clicked chat
                  </div>
                  <hr></hr>
                  <div className="stats"></div>
                </Card.Footer>
              </Card>
            </Col>
            <Col md="8">
              <MUIDataTable
                title={"Tabel Visitor"}
                data={data}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default BoothDetail;
