import React, { forwardRef, useState } from "react";
import ChartistGraph from "react-chartist";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import Funnel, {
  Title,
  Margin,
  Export,
  Tooltip,
  Item,
  Border,
  Label,
} from "devextreme-react/funnel";
import { Divider } from "@material-ui/core";
import DatePicker from "react-date-picker";
import { NoEncryption } from "@material-ui/icons";

const columns = [
  {
    name: "id",
    label: "No",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
    },
  },
  {
    name: "name",
    label: "Name",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "activity",
    label: "Phone Number",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "activity",
    label: "Email",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "activity",
    label: "Both Visit",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "date",
    label: "Date",
    options: {
      filter: true,
      sort: false,
    },
  },
];

const data = [
  {
    name: "Joe James",
    date: "04 Juni 2021",
    time: "09:04:07",
    activity: "Click Booth Travina",
  },
  {
    name: "John Walsh",
    date: "04 Juni 2021",
    time: "09:04:10",
    activity: "Click Booth Kulakan",
  },
  {
    name: "Bob Herm",
    date: "04 Juni 2021",
    time: "09:04:11",
    activity: "Click Booth Nalarin",
  },
  {
    name: "James Houston",
    date: "04 Juni 2021",
    time: "19:04:07",
    activity: "Click Booth Developia",
  },
  {
    name: "Joe James",
    date: "04 Juni 2021",
    time: "09:04:09",
    activity: "Click Booth Travina",
  },
  {
    name: "John Walsh",
    date: "04 Juni 2021",
    time: "19:04:07",
    activity: "Click Booth Kulakan",
  },
  {
    name: "Bob Herm",
    date: "04 Juni 2021",
    time: "08:04:07",
    activity: "Click Booth Nalarin",
  },
  {
    name: "James Houston",
    date: "04 Juni 2021",
    time: "19:04:07",
    activity: "Click Booth Developia",
  },
  {
    name: "Joe James",
    date: "10 Juni 2021",
    time: "09:04:07",
    activity: "Click Boother Travina",
  },
  {
    name: "John Walsh",
    date: "10 Juni 2021",
    time: "20:04:07",
    activity: "Click Boother Kulakan",
  },
  {
    name: "Bob Herm",
    date: "10 Juni 2021",
    time: "10.10",
    activity: "Click Baooth Nalarin",
  },
  {
    name: "James Houston",
    date: "10 Juni 2021",
    time: "19:04:07",
    activity: "Click Booth Developiaage",
  },
  {
    name: "Joe James",
    date: "10 Juni 2021",
    time: "09:04:07",
    activity: "Click Booth Travina",
  },
  {
    name: "John Walsh",
    date: "10 Juni 2021",
    time: "21:04:07",
    activity: "Click Booth Kulakan",
  },
  {
    name: "Bob Herm",
    date: "10 Juni 2021",
    time: "09:04:07",
    activity: "Click Booth Nalarin",
  },
  {
    name: "James Houston",
    date: "10 Juni 2021",
    time: "19:04:07",
    activity: "Click Booth Developia",
  },
];

const options = {
  print: false,
  download: false,
  filter: false,
  search: false,
  viewColumns: false,
  selectableRows: "none",
};

function ExpoVisitor() {
  const [value, onChange] = useState(new Date());
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </Button>
  ));
  return (
    <>
      <Container fluid>
        <Row className="align-items-center">
          <Col lg="8"></Col>
          <Col lg="2" sm="6">
            <Card>
              <DatePicker
                onChange={onChange}
                value={value}
                customInput={<ExampleCustomInput />}
              />
            </Card>
          </Col>
          <Col lg="2" sm="6">
            <Card>
              <DatePicker onChange={onChange} value={value} />
            </Card>
          </Col>
        </Row>

        <Card>
          <MUIDataTable
            title={"Tabel Visitor"}
            data={data}
            columns={columns}
            options={options}
          />
        </Card>
      </Container>
    </>
  );
}

export default ExpoVisitor;
