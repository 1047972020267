import React, { Component, useState } from "react";
import { Card, Container, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import MUIDataTable from "mui-datatables";

import DatePicker from "react-date-picker";
import Dashboard from "./Dashboard";
import DetailBooth from "./DetailBooth";
import Maps from "./Maps";
import Visitor from "./Visitor";
import { Typography } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";

import Delete from "@mui/icons-material/Delete";

import Img1 from "../assets/img/logoStratup/1.png";
import Img2 from "../assets/img/logoStratup/2.png";
import Img3 from "../assets/img/logoStratup/3.png";
import Img4 from "../assets/img/logoStratup/4.png";
import { CardBody } from "reactstrap";

function successAllert() {
  Swal.fire("Success", "Banner berhasil di perbarui", "success");
}

function confirmationAllert() {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
    }
  });
}

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booth: [
        {
          image: Img1,
          name: "Travina",
          visitor: 300,
          id: 1,
          visitorAvrg: 100,
        },
        {
          image: Img2,
          name: "Bibo Course",
          visitor: 300,
          id: 2,
          link: "Booth A",
          visitorAvrg: 100,
        },
        { image: Img3, name: "Booth B", visitor: 300, id: 3, visitorAvrg: 100 },
        {
          image: Img4,
          name: "Nusia Education",
          visitor: 300,
          id: 4,
          visitorAvrg: 100,
        },
      ],
    };
  }
  render() {
    return (
      <>
        <Container fluid>
          <Row>
            {this.state.booth.map((item, index) => (
              <Col lg="3" id="booth">
                <Card className="card-stats">
                  <div className="booth">
                    <CardBody style={{ textAlign: "center" }}>
                      <img
                        src={item.image}
                        className="img-fluid"
                        alt="Logo Bank"
                        style={{ paddingBottom: "15px" }}
                      />
                    </CardBody>
                    <Card.Footer style={{ textAlign: "right" }}>
                      <Button
                        variant="danger"
                        onClick={confirmationAllert}
                        active
                      >
                        <Delete />
                      </Button>
                    </Card.Footer>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>

          <Row>
            <Button variant="primary" onClick={successAllert} active>
              Add
            </Button>
            <Button
              variant="primary"
              onClick={successAllert}
              active
              style={{ marginLeft: 16 }}
            >
              Update
            </Button>
          </Row>
        </Container>
      </>
    );
  }
}

export default Banner;
