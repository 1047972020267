import React, { forwardRef, useState } from "react";
import { Card, Container, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import MUIDataTable from "mui-datatables";

import DatePicker from "react-date-picker";
import Dashboard from "./Dashboard";
import DetailBooth from "./DetailBooth";
import Maps from "./Maps";
import Visitor from "./Visitor";
import { Typography } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AboutUs from "./AboutUs";
import Banner from "./Banner";

function Content() {
  return (
    <>
      <Container fluid>
        <Tabs
          defaultActiveKey="about-us"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="about-us" title="About Us">
            <AboutUs></AboutUs>
          </Tab>
          <Tab eventKey="banner" title="Banner">
            <Banner></Banner>
          </Tab>
        </Tabs>
      </Container>
    </>
  );
}

export default Content;
