import React from "react";
import ChartistGraph from "react-chartist";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { CardBody, CardHeader } from "reactstrap";

function Dashboard() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Visitors</p>
                      <Card.Title as="h4">3.000</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-tap-01 text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Avarge Visitor Perday</p>
                      <Card.Title as="h4">23</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Visitor Perday</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="ct-chart" id="chartHours">
                  <ChartistGraph
                    data={{
                      labels: [
                        "9:00AM",
                        "12:00AM",
                        "3:00PM",
                        "6:00PM",
                        "9:00PM",
                        "12:00PM",
                        "3:00AM",
                        "6:00AM",
                      ],
                      series: [
                        [287, 385, 490, 492, 554, 586, 698, 695],
                        [67, 152, 143, 240, 287, 335, 435, 437],
                        [23, 113, 67, 108, 190, 239, 307, 308],
                      ],
                    }}
                    type="Line"
                    options={{
                      low: 0,
                      high: 800,
                      showArea: false,
                      height: "245px",
                      axisX: {
                        showGrid: false,
                      },
                      lineSmooth: true,
                      showLine: true,
                      showPoint: true,
                      fullWidth: true,
                      chartPadding: {
                        right: 50,
                      },
                    }}
                    responsiveOptions={[
                      [
                        "screen and (max-width: 640px)",
                        {
                          axisX: {
                            labelInterpolationFnc: function (value) {
                              return value[0];
                            },
                          },
                        },
                      ],
                    ]}
                  />
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  Open <i className="fas fa-circle text-danger"></i>
                  Click <i className="fas fa-circle text-warning"></i>
                  Click Second Time
                </div>
                <hr></hr>
                <div className="stats">
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Filter by Profession</Card.Title>
              </Card.Header>
              <Card.Body>
                <div
                  className="ct-chart ct-perfect-fourth"
                  id="chartPreferences"
                >
                  <ChartistGraph
                    data={{
                      labels: ["40%", "20%", "30%" ,"10"],
                      series: [40, 20, 40,10],
                    }}
                    type="Pie"
                  />
                </div>
                <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  IT  <i className="fas fa-circle text-danger"></i>
                  Marketing <i className="fas fa-circle text-warning"></i>
                  Finance <i className="fas fa-circle text-warning"></i>
                  Other
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>      
        <Row>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Marketing Funnel for Visitor</Card.Title>
              </Card.Header>
              <Card.Body>
                <div
                  className="ct-chart ct-perfect-fourth"
                  id="chartPreferences"
                >
                  <ChartistGraph
                    data={{
                      labels: ["40%", "20%", "40%"],
                      series: [40, 20, 40],
                    }}
                    type="Pie"
                  />
                </div>
                <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  Awareness <i className="fas fa-circle text-danger"></i>
                  Engage  <i className="fas fa-circle text-warning"></i>
                  Lead 
                </div>
              </Card.Body>
            </Card>
          </Col>


          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Tabel Visitor</Card.Title>
              </Card.Header>
              <Card.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama</th>
                        <th>Tanggal</th>
                        <th>jam</th>
                        <th>Aktivitas</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Mark</td>
                        <td>04 Juni 2021</td>
                        <td>09.00</td>
                        <td>Boot 1</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Mark</td>
                        <td>04 Juni 2021</td>
                        <td>09.00</td>
                        <td>Boot 1</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Mark</td>
                        <td>04 Juni 2021</td>
                        <td>09.00</td>
                        <td>Boot 1</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Mark</td>
                        <td>04 Juni 2021</td>
                        <td>09.00</td>
                        <td>Boot 1</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Mark</td>
                        <td>04 Juni 2021</td>
                        <td>09.00</td>
                        <td>Boot 1</td>
                      </tr>
                    </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      <Row>
          
      </Row>
          
      </Container>
    </>
  );
}


export default Dashboard;
