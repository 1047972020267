import React, { Component } from "react";

import { Card, Container, Row, Col } from "react-bootstrap";
import SocialMediaForm from "forms/SocialMediaForm";

class SocialMedia extends Component {
  render() {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title as="h4">Edit Social Media</Card.Title>
              </Card.Header>
              <Card.Body>
                <SocialMediaForm></SocialMediaForm>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default SocialMedia;
