import { useFormik } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import validator from "validator";

const SocialMediaForm = (props) => {
  const form = useFormik({
    initialValues: {
      facebookAccount: "facebook.com/maxexpo2021",
      instagramAccount: "instagram.com/maxexpo2021",
      twitterAccount: "twitter.com/maxexpo2021",
      linkedinAccount: "linkedin.com/maxexpo2021",
    },
    onSubmit: async (values) => {
      if (validator.isEmpty(values.facebookAccount))
        return Swal.fire("Failed", "Facebook tidak boleh kosong!", "warning");
      if (validator.isEmpty(values.twitterAccount))
        return Swal.fire("Failed", "Twitter tidak boleh kosong!", "warning");
      if (validator.isEmpty(values.instagramAccount))
        return Swal.fire("Failed", "Instagram tidak boleh kosong!", "warning");
      if (validator.isEmpty(values.linkedinAccount))
        return Swal.fire("Failed", "Linkedin tidak boleh kosong!", "warning");
      Swal.fire("Success", "Berhasil update data social media.", "success");
    },
  });
  return (
    <>
      <Form onSubmit={form.handleSubmit}>
        <Row>
          <Col className="pr-1" sm="12" md="6">
            <Form.Group>
              <label>Facebook Account</label>
              <Form.Control
                defaultValue={form.values.facebookAccount}
                placeholder="Facebook Account"
                type="text"
                name="facebookAccount"
                onChange={form.handleChange}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="pr-1" sm="12" md="6">
            <Form.Group>
              <label>Twitter Account</label>
              <Form.Control
                defaultValue={form.values.twitterAccount}
                placeholder="Twitter Account"
                type="text"
                name="twitterAccount"
                onChange={form.handleChange}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="pr-1" sm="12" md="6">
            <Form.Group>
              <label>Instagram Account</label>
              <Form.Control
                defaultValue={form.values.instagramAccount}
                placeholder="Instagram Account"
                type="text"
                name="instagramAccount"
                onChange={form.handleChange}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="pr-1" sm="12" md="6">
            <Form.Group>
              <label>Linkedin</label>
              <Form.Control
                defaultValue={form.values.linkedinAccount}
                placeholder="Linkedin"
                type="text"
                name="linkedinAccount"
                onChange={form.handleChange}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Button className="btn-fill pull-right" variant="primary" type="submit">
          Update
        </Button>
        <div className="clearfix"></div>
      </Form>
    </>
  );
};

export default SocialMediaForm;
