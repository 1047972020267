import React, { Component, useState } from "react";
import { Row, Col, Form, Image, Button } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const SubCatalog = (props) => {
  return (
    <>
      <Row style={{ marginTop: 32 }}>
        <Col sm="12" md="5">
          <Image
            className="w-50"
            src={
              props.item.logoImg != undefined
                ? URL.createObjectURL(props.item.logoImg)
                : null
            }
          />
          <Form.Group>
            <label>Sub Catalog Picture {props.number}</label>
            <Form.Control
              type="file"
              name="logoImage"
              onChange={(event) => {
                props.logoChange(event.currentTarget.files[0]);
              }}
              accept=".jpg,.jpeg,.png"
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col sm="12" md="7">
          <Form.Group>
            <label>Booth Name {props.number}</label>
            <Form.Control
              placeholder="Booth Name"
              type="text"
              defaultValue={props.item.name}
              name="boothName"
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <label>Attachment {props.number}</label>
            <Form.Control type="file" name="logoImage"></Form.Control>
          </Form.Group>
          <Button
            variant="danger"
            onClick={() => {
              props.removeSubCatalog();
            }}
            active
            style={{ width: 200 }}
          >
            Remove Sub Catalog
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SubCatalog;
