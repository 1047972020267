import ChangePasswordForm from "forms/ChangePasswordForm";
import React, { Component } from "react";

import { Card, Container, Row, Col } from "react-bootstrap";

class Settings extends Component {
  render() {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title as="h4">Change Password</Card.Title>
              </Card.Header>
              <Card.Body>
                <ChangePasswordForm></ChangePasswordForm>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Settings;
