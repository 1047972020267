import React, { forwardRef, useState } from "react";
import { Card, Container, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import MUIDataTable from "mui-datatables";

import DatePicker from "react-date-picker";
import Dashboard from "../Dashboard";
import DetailBooth from "../DetailBooth";
import Maps from "../Maps";
import Visitor from "../Visitor";
import { Typography } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AboutUs from "../AboutUs";
import Banner from "../Banner";
import Description from "./Description";
import ProfileForm from "./ProfieForm";
import Attachment from "views/EditBooth/Attachment";

function EditBooth() {
  return (
    <>
      <Container fluid>
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="profile" title="Profile">
            <ProfileForm></ProfileForm>
          </Tab>
          <Tab eventKey="description" title="Description">
            <Description></Description>
          </Tab>
          <Tab eventKey="attachment" title="Attachment">
            <Attachment></Attachment>
          </Tab>
        </Tabs>
      </Container>
    </>
  );
}

export default EditBooth;
